<template>
  <div class="detail-news" :key="detailsBlog.id">
    <div class="w-full grid h-96 justify-item-center font-extrabold text-white content-center" :style="{
                  backgroundImage: 'url('+detailsBlog.image+')',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backdropFilter: 'blur(10px)',
              }">
        <div class="w-5/6 lg:3/4 sm:w-1/2 mx-auto">
          <span class="font-normal text-xs md:text-lg text-white">
            {{ $moment(detailsBlog.created_at).format("MMMM Mo, YYYY") }}
          </span>
          <h1 class="text-5xl">
            {{ detailsBlog.title }}
          </h1>
        </div>
    </div>
    <div
      class="container max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl mx-auto px-3 md:px-8 mb-8"
    >
      <div
        class="px-3 md:px-16 py-4 md:py-6 w-full"
      >
        <div class="w-full py-3 md:py-6">
          <p
            class="font-normal text-black2 text-base md:text-lg leading-5 md:leading-7"
            v-html="detailsBlog.content"
          >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import SliderNews from "@/components/SliderNews";

export default {
  name: "BlogDetail",
  components: {
    Breadcrumb,
    SliderNews,
  },
  data: () => ({
    detailsBlog: {},
  }),
  computed: {
  },
  beforeRouteEnter(to, from, next) {
      if (to.params.detail === undefined) {
          next({ name: 'BlogList' });
      } else {
          next();
      }
  },
  async created() {
      this.detailsBlog = this.$route.params.detail;
  },
  methods: {
  },
};
</script>
